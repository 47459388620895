/*!  #gFooter
================================================ */
#gFooter { 
	border-top: 6px solid #257e61;
	.footerIn{ 
		background-color: #0f6b4d;
		padding-bottom: 17px;
		.freeArea{ 
			padding: 24px 0 10px;
			position: relative;
			.free{ 
				margin-bottom: 15px;
				.txtFree{ 
					width: 192px;
					margin-bottom: 7px;
				}
				.txt{ 
					font-size: 1.6rem;
					font-weight: 500;
					color: #fff;
					line-height: 1.5;
				}
			}
			.detail{ 
				border: 1px solid #fff;
				border-radius: 2px;
				text-align: center;
				margin-bottom: 10px;
				position: relative;
				z-index: 1;
				dt{ 
					background-color: #0f6b4d;
					color: #fff;
					font-weight: 500;
					padding: 5px 0;
					span{ 
						font-size: 1.9rem;
						font-weight: bold;
						line-height: 1.5;
						display: inline-block;
						margin-right: 6px;
					}
				}
				dd{ 
					background-color: #fff;
					font-weight: 500;
					padding: 11px 0;
				}
			}
			.ex{ 
				color: #fff;
				font-size: 1.3rem;
			}
			.img{ 
				position: absolute;
				top: 0;
				right: 0;
				width: 138px;
				margin: -20px -15px 0 0;
			}
		}
		.contactArea{ 
			background-color: #fff;
			padding: 15px 5px 19px;
			.read{ 
				text-align: center;
				font-size: 1.3rem;
				line-height: 1.7;
				margin-bottom: 10px;
			}
			.infoArea{ 
				.txt{ 
					text-align: center;
					background-color: #f4f3f0;
					margin-bottom: 14px;
					font-weight: 500;
					padding: 5px 0;
				}
				.telArea{ 
					.btnTel{ 
						padding: 0 15px;
						margin-bottom: 20px;
						a{ 
							display: block;
							background-color: #257e61;
							box-shadow: 0px 4px 0px 0px rgba(224, 224, 224, 0.4);
							color: #fff;
							text-align: center;
							text-decoration: none;
							font-size: 2.9rem;
							padding: 10px 0;
							font-weight: bold;
							letter-spacing: 0;
							border-radius: 6px;
							&:before{ 
								content: '';
								background: url(../img/common/footer/ico_tel01.png) 0 0 no-repeat;
								background-size: contain;
								width: 20px;
								height: 21px;
								display: inline-block;
								margin: 0 7px -1px 0;
							}
						}
					}
					.timeArea{ 
						margin-bottom: 20px;
						.time{ 
							padding: 0 15px;
							+.time{ 
								margin-top: 5px;
							}
							dt{ 
								font-size: 1.2rem;
								text-align: center;
								width: 65px;
								float: left;
								background-color: #f6faf5;
								line-height: 1.4;
							}
							dd{ 
								padding-left: 70px;
								font-size: 1.2rem;
								letter-spacing: -0.01em;
								line-height: 1.4;
								span{ 
									font-size: 1.1rem;
									color: #91908e;
									letter-spacing: 0em;
								}
							}
						}
					}
				}
				.webArea{ 
					.btnContact{ 
						padding: 0 15px;
						a{ 
							display: block;
							background-color: #fc954a;
							box-shadow: 0px 4px 0px 0px rgba(224, 224, 224, 0.4);
							color: #fff;
							text-align: center;
							text-decoration: none;
							font-size: 1.9rem;
							padding: 17px 0;
							font-weight: 500;
							letter-spacing: 0;
							border-radius: 6px;
							&:before{ 
								content: '';
								background: url(../img/common/footer/ico_mail01.png) 0 0 no-repeat;
								background-size: contain;
								width: 26px;
								height: 19px;
								display: inline-block;
								margin: 0 10px -3px 0;
							}
						}
					}
				}
			}
		}
	}
	.ftNav{ 
		padding: 0;
		.list{ 
			.item{ 
				float: left;
				width: 50%;
				box-sizing: border-box;
				border-bottom: 1px solid #ccc;
				&:nth-child(even){ 
					border-left: 1px solid #ccc;
				}
				a{ 
					display: block;
					text-decoration: none;
					padding: 13px 15px;
					color: #257e61;
					font-weight: 500;
				}
			}
		}
	}
	.ftBtm{ 
		background-color: #f9f6ef;
		padding: 15px 0 20px;
		.logo{ 
			width: 208px;
			margin-bottom: 10px;
		}
		.copyright{ 
			font-size: 1.2rem;
			color: #9f9b94;
		}
	}
	@include mq(pc) { 
		.footerIn{ 
			padding-bottom: 40px;
			.freeArea{ 
				padding: 35px 0 15px;
				.free{ 
					margin-bottom: 16px;
					display: table;
					width: 100%;
					.txtFree{ 
						display: table-cell;
						width: 389px;
						margin-bottom: 0;
					}
					.txt{ 
						display: table-cell;
						vertical-align: middle;
						font-size: 2.4rem;
						padding-left: 25px;
					}
				}
				.detail{ 
					display: table;
					width: 753px;
					border: 2px solid #fff;
					margin-bottom: 15px;
					box-sizing: border-box;
					dt{ 
						display: table-cell;
						width: 260px;
						vertical-align: middle;
						font-size: 1.8rem;
						padding: 0;
						span{ 
							font-size: 2.6rem;
							line-height: 1.4;
						}
					}
					dd{ 
						padding: 14px 0;
						font-size: 1.7rem;
					}
				}
				.ex{ 
					color: #fff;
					font-size: 1.3rem;
				}
				.img{ 
					width: 326px;
					margin: -44px 18px 0 0;
				}
			}
			.contactArea{ 
				padding: 25px 40px 28px;
				position: relative;
				z-index: 1;
				.read{ 
					margin-bottom: 15px;
					font-weight: 500;
				}
				.infoArea{ 
					.txt{ 
						margin-bottom: 15px;
						font-size: 1.7rem;
						padding: 10px 0;
					}
					.telArea{ 
						float: left;
						width: 500px;
						.btnTel{ 
							padding: 0;
							margin-bottom: 10px;
							padding-bottom: 8px;
							border-bottom: 1px solid #e0e0e0;
							a{ 
								display: block;
								background-color: #fff;
								color: #4c463d;
								box-shadow: none;
								font-size: 5.4rem;
								padding: 0;
								border-radius: 0;
								line-height: 1;
								&:before{ 
									background: url(../img/common/footer/ico_tel02.png) 0 0 no-repeat;
									background-size: contain;
									width: 35px;
									height: 37px;
									margin: 0 14px -2px 0;
								}
							}
						}
						.timeArea{ 
							margin-bottom: 0;
							.time{ 
								padding: 0;
								float: left;
								+.time{ 
									margin-top: 0;
									margin-left: 15px;
								}
								dt{ 
									width: 60px;
									line-height: 1.7;
								}
								dd{ 
									padding-left: 65px;
									line-height: 1.7;
								}
							}
						}
					}
					.webArea{ 
						float: right;
						width: 510px;
						border-left: 1px solid #ebebeb;
						box-sizing: border-box;
						padding-left: 10px;
						.btnContact{ 
							padding: 0;
							width: 410px;
							margin: 25px auto 0;
							a{ 
								font-size: 2.0rem;
								padding: 24px 0;
								&:before{ 
									margin: 0 15px -2px 0;
								}
							}
						}
					}
				}
			}
		}
		.ftNav{ 
			padding: 0;
			.list{ 
				margin: 20px 0;
				.item{ 
					width: calc(1100px / 6);
					text-align: center;
					border-bottom: none;
					border-left: 1px solid #d9d8cf;
					padding: 0 5px;
					&:nth-child(even){ 
						border-left: 1px solid #d9d8cf;
					}
					&:last-child{ 
						border-right: 1px solid #d9d8cf;
					}
					a{ 
						padding: 4px 0px;
						@include trans;
						&:hover{ 
							background-color: #f6faf5;
						}
					}
				}
			}
		}
		.ftBtm{ 
			padding: 16px 0 20px;
			.logo{ 
				float: left;
				width: 238px;
				margin-bottom: 0;
				img{ 
					vertical-align: middle;
				}
			}
			.copyright{ 
				float: right;
				font-size: 1.3rem;
				padding-top: 4px;
				padding-right: 9px;
			}
		}
	}
}


/* #pagetop
================================================ */
#pagetop { 
	position: fixed;
	bottom: 17px;
	right: 15px;
	opacity: 0;
	visibility: hidden;
	z-index: 100;
	@include trans($prop: opacity);
	&.is_view { 
		opacity: 1;
		visibility: visible;
	}
	a { 
		width: 50px;
		font-size: 0;
	}
	@include mq(pc) { 
		position: fixed;
		bottom: 25px;
		right: 35px;
		a{ 
			width: 80px;
		}
	}
}