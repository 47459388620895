/*!  #mainVisual TOPページ
================================================ */
.mainVisualWrap{ 
	margin: 15px 0 40px;
	#mainVisual{ 
		background: url(../img/home/bg_main_sp.png) 0 0 no-repeat;
		background-size: cover;
		height: 400px;
		position: relative;
		margin-bottom: 15px;
		.txtMain{ 
			position: absolute;
			bottom: 23px;
			padding: 0 0 0 15px;
			width: 286px;
		}
	}
	.boxConsultation{ 
		border-radius: 4px;
		border: 3px solid #257e61;
		margin: 0 15px;
		padding: 0 1px 1px;
		.txt01{ 
			text-align: center;
			color: #534b3f;
			font-size: 1.7rem;
			font-weight: 500;
			padding: 7px 0;
			.txtConsultation{ 
				color: #257e61;
				font-size: 2.3rem;
				font-weight: bold;
				line-height: 1.2;
			}
		}
		.txt02{ 
			background-color: #0f6b4d;
			color: #fff;
			text-align: center;
			font-weight: 500;
			line-height: 2;
			padding: 12px 0;
			letter-spacing: 0;
			.stxt{ 
				display: block;
				font-size: 1.2rem;
			}
		}
	}
	@include mq(pc) { 
		margin: 0 0 30px;
		#mainVisual{ 
			background: url(../img/home/bg_main_pc.png) 50% 50% no-repeat;
			background-size: cover;
			height: 681px;
			margin-bottom: 0;
			.txtMain{ 
				width: 1100px;
				bottom: 80px;
				left: 0;
				right: 0;
				margin: auto;
				padding: 0;
				
				img{ 
					width: 645px;
				}
			}
		}
		.consultationWrap{ 
			width: 1100px;
			margin: auto;
			position: relative;
		}
		.boxConsultation{ 
			width: 263px;
			height: 263px;
			border-radius: 50%;
			border: 6px solid #257e61;
			margin: 0;
			padding: 0;
			overflow: hidden;
			background-color: #0f6b4d;
			box-sizing: border-box;
			position: absolute;
			top: -250px;
			right: 0;
			.txt01{ 
				background-color: #fff;
				font-size: 2.1rem;
				padding: 35px 0 10px 0;
				.txtConsultation{ 
					display: block;
					font-size: 3.3rem;
					line-height: 1.3;
				}
			}
			.txt02{ 
				font-size: 1.8rem;
				line-height: 1.5;
				padding: 15px 30px 0;
				letter-spacing: 0;
				.stxt{ 
					font-size: 1.0rem;
					padding-left: 1em;
					text-indent: -1em;
					text-align: left;
					margin: 5px 23px 0;
				}
			}
		}
	}
}



/*!  #mainImg 下層ページ
================================================ */
/*!  #mainimg
================================================ */
#mainImg { 
	background-size: cover;
	background-position: 50% 50%;
	height: 160px;
	padding-top: 44px;
	box-sizing: border-box;
	.titMain{ 
		text-align: center;
		color: #fff;
		font-size: 3.0rem;
		font-weight: bold;
	}
	.titMainEn{ 
		text-align: center;
		color: #fff;
		font-size: 1.1rem;
		font-weight: 500;
	}
	@include mq(pc) { 
		height: 300px;
		padding-top: 106px;
		.titMain{ 
			font-size: 4.6rem;
		}
		.titMainEn{ 
			font-size: 1.6rem;
		}
	}
}



