@charset "UTF-8";
/*=============================================================
 02_base
=============================================================*/
/*!  02_base
================================================ */
@media all and (min-width: 600px) {  html {  overflow: auto; } }

body {  min-width: 320px; font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif; color: #4c463d; font-size: 1.5rem; line-height: 1.6; letter-spacing: .08em; position: relative; }

@media all and (min-width: 600px) {  body {  min-width: 1100px; overflow: hidden; border-top: 4px solid #257e61; } }

a {  color: #4c463d; text-decoration: underline; }

@media all and (min-width: 600px) {  a:hover {  text-decoration: none; } }

.alpha {  display: block; text-decoration: none; }

@media all and (min-width: 600px) {  .alpha {  transition: all 0.3s ease; }
  .alpha:hover {  opacity: .7; } }

.roboto {  font-family: 'Roboto', sans-serif; }

.serif {  font-family: 'Noto Serif JP', serif; }

img {  width: 100%; height: auto; }

.viewPc {  display: none; }

@media all and (min-width: 600px) {  .viewSp {  display: none; }
  .viewPc {  display: block; } }

.cf:after {  content: ''; display: block; clear: both; }

#mediaQuery {  display: none; font-family: 'sp'; }

@media all and (min-width: 600px) {  #mediaQuery {  font-family: 'pc'; } }

/*! layout
-------------------------------------- */
/*=============================================================
 03_layout
=============================================================*/
/* #gHeader
================================================ */
#gHeader {  border-bottom: 1px solid #d9d8cf; padding: 0 10px 10px; }

@media all and (min-width: 600px) {  #gHeader {  border-bottom: none; padding: 0; margin-bottom: 30px; } }

/* #hdInfo
-------------------------------------- */
#hdInfo .hdTxtLogo {  float: left; padding: 16px 0 0 0; }

#hdInfo .hdTxtLogo .logo {  width: 152px; }

#hdInfo .hdBtns {  float: right; width: 100px; padding-top: 10px; }

#hdInfo .hdBtns .btnTel {  float: left; width: 45px; }

#hdInfo .hdBtns .btnGnav {  float: left; width: 45px; height: 47px; margin-left: 10px; background: url(../img/common/header/btn_menu01.png) 0 0 no-repeat; background-size: contain; }

#hdInfo .hdBtns .btnGnav.is_open {  background: url(../img/common/header/btn_menu02.png) 0 0 no-repeat; background-size: contain; }

@media all and (min-width: 600px) {  #hdInfo {  width: 1100px; margin: auto; padding: 18px 0 26px; }
  #hdInfo .hdTxtLogo {  float: left; width: 550px; padding: 0; }
  #hdInfo .hdTxtLogo .hdTxt {  font-size: 1.1rem; color: #a6a6a6; line-height: 1; margin-bottom: 18px; letter-spacing: 0; }
  #hdInfo .hdTxtLogo .logo {  width: 281px; }
  #hdInfo .hdContact {  float: right; width: 500px; }
  #hdInfo .hdContact .telArea {  margin-bottom: 5px; }
  #hdInfo .hdContact .telArea .read {  font-size: 1.2rem; line-height: 1.4; width: 247px; float: left; letter-spacing: 0; border: 1px solid #d9d8cf; padding: 10px 0px 10px 15px; box-sizing: border-box; position: relative; }
  #hdInfo .hdContact .telArea .read:before {  content: ''; background: url(../img/common/header/ico_arw01.png) 0 0 no-repeat; background-size: contain; width: 6px; height: 11px; position: absolute; top: 21px; right: -6px; }
  #hdInfo .hdContact .telArea .tel {  width: 237px; float: right; }
  #hdInfo .hdContact .telArea .tel .txt {  margin: -3px 0 4px; }
  #hdInfo .hdContact .telArea .tel .txt span {  display: inline-block; font-size: 1.1rem; color: #fff; text-align: center; letter-spacing: 0; padding: 2px 0; }
  #hdInfo .hdContact .telArea .tel .txt .txt01 {  background-color: #fc954a; width: 66px; }
  #hdInfo .hdContact .telArea .tel .txt .txt02 {  width: 171px; background-color: #257e61; }
  #hdInfo .hdContact .telArea .tel .num {  font-size: 3.4rem; letter-spacing: -0.025em; font-weight: 500; line-height: 1; }
  #hdInfo .hdContact .telArea .tel .num:before {  content: ''; background: url(../img/common/header/ico_tel01.png) 0 0 no-repeat; background-size: contain; width: 22px; height: 22px; display: inline-block; margin: 0 6px 0px 0; }
  #hdInfo .hdContact .timeArea .time {  float: left; width: 275px; }
  #hdInfo .hdContact .timeArea .time + .time {  width: auto; }
  #hdInfo .hdContact .timeArea .time dt {  float: left; width: 57px; text-align: center; font-size: 1.1rem; letter-spacing: 0; background-color: #f6faf5; }
  #hdInfo .hdContact .timeArea .time dd {  font-size: 1.1rem; letter-spacing: 0; padding-left: 60px; }
  #hdInfo .hdContact .timeArea .time dd span {  font-size: 1.0rem; color: #91908e; } }

/* #gNav
================================================ */
#gNav {  display: none; position: absolute; top: 68px; right: 0; width: 100%; z-index: 10000; background-color: #fff; }

#gNav .list {  margin-bottom: 25px; }

#gNav .list .item {  border-bottom: 1px solid #ccc; }

#gNav .list .item a {  display: block; font-weight: 500; color: #257e61; text-decoration: none; padding: 15px 16px; }

#gNav .btnClose {  width: 305px; margin: 0 auto 28px; background-color: #f4f3f0; border-radius: 6px; box-shadow: 0px 3px 0px 0px rgba(51, 51, 51, 0.2); text-align: center; font-size: 1.6rem; font-weight: 500; color: #257e61; padding: 15px 0; }

#gNav .btnClose:before {  content: ''; background: url(../img/common/header/ico_close01.png) 0 0 no-repeat; background-size: contain; width: 17px; height: 17px; display: inline-block; margin: 0px 10px -2px 0; }

@media all and (min-width: 600px) {  #gNav {  display: block; position: static; width: 1100px; margin: auto; }
  #gNav .list {  margin-bottom: 0; }
  #gNav .list .item {  float: left; width: calc(1100px / 6); border-bottom: none; text-align: center; border-left: 1px solid #d9d8cf; box-sizing: border-box; padding: 0 5px; }
  #gNav .list .item:last-child {  border-right: 1px solid #d9d8cf; }
  #gNav .list .item a {  padding: 4px 0px; transition: all 0.3s ease; }
  #gNav .list .item a:hover {  background-color: #f6faf5; } }

/* #overlay
================================================ */
#overlay {  display: none; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.3); position: absolute; top: 68px; left: 0; z-index: 999; }

@media all and (min-width: 600px) {  #overlay {  display: none !important; } }

/*!  #mainVisual TOPページ
================================================ */
.mainVisualWrap {  margin: 15px 0 40px; }

.mainVisualWrap #mainVisual {  background: url(../img/home/bg_main_sp.png) 0 0 no-repeat; background-size: cover; height: 400px; position: relative; margin-bottom: 15px; }

.mainVisualWrap #mainVisual .txtMain {  position: absolute; bottom: 23px; padding: 0 0 0 15px; width: 286px; }

.mainVisualWrap .boxConsultation {  border-radius: 4px; border: 3px solid #257e61; margin: 0 15px; padding: 0 1px 1px; }

.mainVisualWrap .boxConsultation .txt01 {  text-align: center; color: #534b3f; font-size: 1.7rem; font-weight: 500; padding: 7px 0; }

.mainVisualWrap .boxConsultation .txt01 .txtConsultation {  color: #257e61; font-size: 2.3rem; font-weight: bold; line-height: 1.2; }

.mainVisualWrap .boxConsultation .txt02 {  background-color: #0f6b4d; color: #fff; text-align: center; font-weight: 500; line-height: 2; padding: 12px 0; letter-spacing: 0; }

.mainVisualWrap .boxConsultation .txt02 .stxt {  display: block; font-size: 1.2rem; }

@media all and (min-width: 600px) {  .mainVisualWrap {  margin: 0 0 30px; }
  .mainVisualWrap #mainVisual {  background: url(../img/home/bg_main_pc.png) 50% 50% no-repeat; background-size: cover; height: 681px; margin-bottom: 0; }
  .mainVisualWrap #mainVisual .txtMain {  width: 1100px; bottom: 80px; left: 0; right: 0; margin: auto; padding: 0; }
  .mainVisualWrap #mainVisual .txtMain img {  width: 645px; }
  .mainVisualWrap .consultationWrap {  width: 1100px; margin: auto; position: relative; }
  .mainVisualWrap .boxConsultation {  width: 263px; height: 263px; border-radius: 50%; border: 6px solid #257e61; margin: 0; padding: 0; overflow: hidden; background-color: #0f6b4d; box-sizing: border-box; position: absolute; top: -250px; right: 0; }
  .mainVisualWrap .boxConsultation .txt01 {  background-color: #fff; font-size: 2.1rem; padding: 35px 0 10px 0; }
  .mainVisualWrap .boxConsultation .txt01 .txtConsultation {  display: block; font-size: 3.3rem; line-height: 1.3; }
  .mainVisualWrap .boxConsultation .txt02 {  font-size: 1.8rem; line-height: 1.5; padding: 15px 30px 0; letter-spacing: 0; }
  .mainVisualWrap .boxConsultation .txt02 .stxt {  font-size: 1.0rem; padding-left: 1em; text-indent: -1em; text-align: left; margin: 5px 23px 0; } }

/*!  #mainImg 下層ページ
================================================ */
/*!  #mainimg
================================================ */
#mainImg {  background-size: cover; background-position: 50% 50%; height: 160px; padding-top: 44px; box-sizing: border-box; }

#mainImg .titMain {  text-align: center; color: #fff; font-size: 3.0rem; font-weight: bold; }

#mainImg .titMainEn {  text-align: center; color: #fff; font-size: 1.1rem; font-weight: 500; }

@media all and (min-width: 600px) {  #mainImg {  height: 300px; padding-top: 106px; }
  #mainImg .titMain {  font-size: 4.6rem; }
  #mainImg .titMainEn {  font-size: 1.6rem; } }

/*!  #breadcrumb
================================================ */
#breadcrumb ol {  font-size: 0; padding: 15px 0; }

#breadcrumb ol li {  display: inline-block; font-size: 1.4rem; color: #4c463d; }

#breadcrumb ol li + li:before {  content: ""; width: 0; height: 0; border-style: solid; border-width: 4px 0 4px 5px; border-color: transparent transparent transparent #257e61; display: inline-block; margin: 0 6px 1px 4px; }

#breadcrumb ol li a {  font-size: 1.4rem; text-decoration: none; color: #257e61; }

#breadcrumb ol li a:hover {  text-decoration: underline; }

/*!  #sidebar
================================================ */
/*!  #gFooter
================================================ */
#gFooter {  border-top: 6px solid #257e61; }

#gFooter .footerIn {  background-color: #0f6b4d; padding-bottom: 17px; }

#gFooter .footerIn .freeArea {  padding: 24px 0 10px; position: relative; }

#gFooter .footerIn .freeArea .free {  margin-bottom: 15px; }

#gFooter .footerIn .freeArea .free .txtFree {  width: 192px; margin-bottom: 7px; }

#gFooter .footerIn .freeArea .free .txt {  font-size: 1.6rem; font-weight: 500; color: #fff; line-height: 1.5; }

#gFooter .footerIn .freeArea .detail {  border: 1px solid #fff; border-radius: 2px; text-align: center; margin-bottom: 10px; position: relative; z-index: 1; }

#gFooter .footerIn .freeArea .detail dt {  background-color: #0f6b4d; color: #fff; font-weight: 500; padding: 5px 0; }

#gFooter .footerIn .freeArea .detail dt span {  font-size: 1.9rem; font-weight: bold; line-height: 1.5; display: inline-block; margin-right: 6px; }

#gFooter .footerIn .freeArea .detail dd {  background-color: #fff; font-weight: 500; padding: 11px 0; }

#gFooter .footerIn .freeArea .ex {  color: #fff; font-size: 1.3rem; }

#gFooter .footerIn .freeArea .img {  position: absolute; top: 0; right: 0; width: 138px; margin: -20px -15px 0 0; }

#gFooter .footerIn .contactArea {  background-color: #fff; padding: 15px 5px 19px; }

#gFooter .footerIn .contactArea .read {  text-align: center; font-size: 1.3rem; line-height: 1.7; margin-bottom: 10px; }

#gFooter .footerIn .contactArea .infoArea .txt {  text-align: center; background-color: #f4f3f0; margin-bottom: 14px; font-weight: 500; padding: 5px 0; }

#gFooter .footerIn .contactArea .infoArea .telArea .btnTel {  padding: 0 15px; margin-bottom: 20px; }

#gFooter .footerIn .contactArea .infoArea .telArea .btnTel a {  display: block; background-color: #257e61; box-shadow: 0px 4px 0px 0px rgba(224, 224, 224, 0.4); color: #fff; text-align: center; text-decoration: none; font-size: 2.9rem; padding: 10px 0; font-weight: bold; letter-spacing: 0; border-radius: 6px; }

#gFooter .footerIn .contactArea .infoArea .telArea .btnTel a:before {  content: ''; background: url(../img/common/footer/ico_tel01.png) 0 0 no-repeat; background-size: contain; width: 20px; height: 21px; display: inline-block; margin: 0 7px -1px 0; }

#gFooter .footerIn .contactArea .infoArea .telArea .timeArea {  margin-bottom: 20px; }

#gFooter .footerIn .contactArea .infoArea .telArea .timeArea .time {  padding: 0 15px; }

#gFooter .footerIn .contactArea .infoArea .telArea .timeArea .time + .time {  margin-top: 5px; }

#gFooter .footerIn .contactArea .infoArea .telArea .timeArea .time dt {  font-size: 1.2rem; text-align: center; width: 65px; float: left; background-color: #f6faf5; line-height: 1.4; }

#gFooter .footerIn .contactArea .infoArea .telArea .timeArea .time dd {  padding-left: 70px; font-size: 1.2rem; letter-spacing: -0.01em; line-height: 1.4; }

#gFooter .footerIn .contactArea .infoArea .telArea .timeArea .time dd span {  font-size: 1.1rem; color: #91908e; letter-spacing: 0em; }

#gFooter .footerIn .contactArea .infoArea .webArea .btnContact {  padding: 0 15px; }

#gFooter .footerIn .contactArea .infoArea .webArea .btnContact a {  display: block; background-color: #fc954a; box-shadow: 0px 4px 0px 0px rgba(224, 224, 224, 0.4); color: #fff; text-align: center; text-decoration: none; font-size: 1.9rem; padding: 17px 0; font-weight: 500; letter-spacing: 0; border-radius: 6px; }

#gFooter .footerIn .contactArea .infoArea .webArea .btnContact a:before {  content: ''; background: url(../img/common/footer/ico_mail01.png) 0 0 no-repeat; background-size: contain; width: 26px; height: 19px; display: inline-block; margin: 0 10px -3px 0; }

#gFooter .ftNav {  padding: 0; }

#gFooter .ftNav .list .item {  float: left; width: 50%; box-sizing: border-box; border-bottom: 1px solid #ccc; }

#gFooter .ftNav .list .item:nth-child(even) {  border-left: 1px solid #ccc; }

#gFooter .ftNav .list .item a {  display: block; text-decoration: none; padding: 13px 15px; color: #257e61; font-weight: 500; }

#gFooter .ftBtm {  background-color: #f9f6ef; padding: 15px 0 20px; }

#gFooter .ftBtm .logo {  width: 208px; margin-bottom: 10px; }

#gFooter .ftBtm .copyright {  font-size: 1.2rem; color: #9f9b94; }

@media all and (min-width: 600px) {  #gFooter .footerIn {  padding-bottom: 40px; }
  #gFooter .footerIn .freeArea {  padding: 35px 0 15px; }
  #gFooter .footerIn .freeArea .free {  margin-bottom: 16px; display: table; width: 100%; }
  #gFooter .footerIn .freeArea .free .txtFree {  display: table-cell; width: 389px; margin-bottom: 0; }
  #gFooter .footerIn .freeArea .free .txt {  display: table-cell; vertical-align: middle; font-size: 2.4rem; padding-left: 25px; }
  #gFooter .footerIn .freeArea .detail {  display: table; width: 753px; border: 2px solid #fff; margin-bottom: 15px; box-sizing: border-box; }
  #gFooter .footerIn .freeArea .detail dt {  display: table-cell; width: 260px; vertical-align: middle; font-size: 1.8rem; padding: 0; }
  #gFooter .footerIn .freeArea .detail dt span {  font-size: 2.6rem; line-height: 1.4; }
  #gFooter .footerIn .freeArea .detail dd {  padding: 14px 0; font-size: 1.7rem; }
  #gFooter .footerIn .freeArea .ex {  color: #fff; font-size: 1.3rem; }
  #gFooter .footerIn .freeArea .img {  width: 326px; margin: -44px 18px 0 0; }
  #gFooter .footerIn .contactArea {  padding: 25px 40px 28px; position: relative; z-index: 1; }
  #gFooter .footerIn .contactArea .read {  margin-bottom: 15px; font-weight: 500; }
  #gFooter .footerIn .contactArea .infoArea .txt {  margin-bottom: 15px; font-size: 1.7rem; padding: 10px 0; }
  #gFooter .footerIn .contactArea .infoArea .telArea {  float: left; width: 500px; }
  #gFooter .footerIn .contactArea .infoArea .telArea .btnTel {  padding: 0; margin-bottom: 10px; padding-bottom: 8px; border-bottom: 1px solid #e0e0e0; }
  #gFooter .footerIn .contactArea .infoArea .telArea .btnTel a {  display: block; background-color: #fff; color: #4c463d; box-shadow: none; font-size: 5.4rem; padding: 0; border-radius: 0; line-height: 1; }
  #gFooter .footerIn .contactArea .infoArea .telArea .btnTel a:before {  background: url(../img/common/footer/ico_tel02.png) 0 0 no-repeat; background-size: contain; width: 35px; height: 37px; margin: 0 14px -2px 0; }
  #gFooter .footerIn .contactArea .infoArea .telArea .timeArea {  margin-bottom: 0; }
  #gFooter .footerIn .contactArea .infoArea .telArea .timeArea .time {  padding: 0; float: left; }
  #gFooter .footerIn .contactArea .infoArea .telArea .timeArea .time + .time {  margin-top: 0; margin-left: 15px; }
  #gFooter .footerIn .contactArea .infoArea .telArea .timeArea .time dt {  width: 60px; line-height: 1.7; }
  #gFooter .footerIn .contactArea .infoArea .telArea .timeArea .time dd {  padding-left: 65px; line-height: 1.7; }
  #gFooter .footerIn .contactArea .infoArea .webArea {  float: right; width: 510px; border-left: 1px solid #ebebeb; box-sizing: border-box; padding-left: 10px; }
  #gFooter .footerIn .contactArea .infoArea .webArea .btnContact {  padding: 0; width: 410px; margin: 25px auto 0; }
  #gFooter .footerIn .contactArea .infoArea .webArea .btnContact a {  font-size: 2.0rem; padding: 24px 0; }
  #gFooter .footerIn .contactArea .infoArea .webArea .btnContact a:before {  margin: 0 15px -2px 0; }
  #gFooter .ftNav {  padding: 0; }
  #gFooter .ftNav .list {  margin: 20px 0; }
  #gFooter .ftNav .list .item {  width: calc(1100px / 6); text-align: center; border-bottom: none; border-left: 1px solid #d9d8cf; padding: 0 5px; }
  #gFooter .ftNav .list .item:nth-child(even) {  border-left: 1px solid #d9d8cf; }
  #gFooter .ftNav .list .item:last-child {  border-right: 1px solid #d9d8cf; }
  #gFooter .ftNav .list .item a {  padding: 4px 0px; transition: all 0.3s ease; }
  #gFooter .ftNav .list .item a:hover {  background-color: #f6faf5; }
  #gFooter .ftBtm {  padding: 16px 0 20px; }
  #gFooter .ftBtm .logo {  float: left; width: 238px; margin-bottom: 0; }
  #gFooter .ftBtm .logo img {  vertical-align: middle; }
  #gFooter .ftBtm .copyright {  float: right; font-size: 1.3rem; padding-top: 4px; padding-right: 9px; } }

/* #pagetop
================================================ */
#pagetop {  position: fixed; bottom: 17px; right: 15px; opacity: 0; visibility: hidden; z-index: 100; transition: opacity 0.3s ease; }

#pagetop.is_view {  opacity: 1; visibility: visible; }

#pagetop a {  width: 50px; font-size: 0; }

@media all and (min-width: 600px) {  #pagetop {  position: fixed; bottom: 25px; right: 35px; }
  #pagetop a {  width: 80px; } }

/*=============================================================
 05_helper
=============================================================*/
/*!  .js-name
================================================ */
/*=============================================================
 06_lib
=============================================================*/
