/* #gHeader
================================================ */
#gHeader { 
	border-bottom: 1px solid #d9d8cf;
	padding: 0 10px 10px;
	@include mq(pc) { 
		border-bottom: none;
		padding: 0;
		margin-bottom: 30px;
	}
}
/* #hdInfo
-------------------------------------- */
#hdInfo { 
	.hdTxtLogo{ 
		float: left;
		padding: 16px 0 0 0;
		.logo{ 
			width: 152px;
		}
	}
	.hdBtns{ 
		float: right;
		width: 100px;
		padding-top: 10px;
		.btnTel{ 
			float: left;
			width: 45px;
		}
		.btnGnav { 
			float: left;
			width: 45px;
			height: 47px;
			margin-left: 10px;
			background: url(../img/common/header/btn_menu01.png) 0 0 no-repeat;
			background-size: contain;
			&.is_open { 
				background: url(../img/common/header/btn_menu02.png) 0 0 no-repeat;
				background-size: contain;
			}
		}
	}
	@include mq(pc) { 
		width: 1100px;
		margin: auto;
		padding: 18px 0 26px;
		.hdTxtLogo{ 
			float: left;
			width: 550px;
			padding: 0;
			.hdTxt{ 
				font-size: 1.1rem;
				color: #a6a6a6;
				line-height: 1;
				margin-bottom: 18px;
				letter-spacing: 0;
			}
			.logo{ 
				width: 281px;
			}
		}
		.hdContact{ 
			float: right;
			width: 500px;
			.telArea{ 
				margin-bottom: 5px;
				.read{ 
					font-size: 1.2rem;
					line-height: 1.4;
					width: 247px;
					float: left;
					letter-spacing: 0;
					border: 1px solid #d9d8cf;
					padding: 10px 0px 10px 15px;
					box-sizing: border-box;
					position: relative;
					&:before{ 
						content: '';
						background: url(../img/common/header/ico_arw01.png) 0 0 no-repeat;
						background-size: contain;
						width: 6px;
						height: 11px;
						position: absolute;
						top: 21px;
						right: -6px;
					}
				}
				.tel{ 
					width: 237px;
					float: right;
					.txt{ 
						margin: -3px 0 4px;
						span{ 
							display: inline-block;
							font-size: 1.1rem;
							color: #fff;
							text-align: center;
							letter-spacing: 0;
							padding: 2px 0;
						}
						.txt01{ 
							background-color: #fc954a;
							width: 66px;
							
						}
						.txt02{ 
							width: 171px;
							background-color: #257e61;
						}
					}
					.num{ 
						font-size: 3.4rem;
						letter-spacing: -0.025em;
						font-weight: 500;
						line-height: 1;
						&:before{ 
							content: '';
							background: url(../img/common/header/ico_tel01.png) 0 0 no-repeat;
							background-size: contain;
							width: 22px;
							height: 22px;
							display: inline-block;
							margin: 0 6px 0px 0;
						}
					}
				}
			}
			.timeArea{ 
				.time{ 
					float: left;
					width: 275px;
					+.time{ 
						width: auto;
					}
					dt{ 
						float: left;
						width: 57px;
						text-align: center;
						font-size: 1.1rem;
						letter-spacing: 0;
						background-color: #f6faf5;
					}
					dd{ 
						font-size: 1.1rem;
						letter-spacing: 0;
						padding-left: 60px;
						span{ 
							font-size: 1.0rem;
							color: #91908e;
						}
					}
				}
			}
		}
	}
}

/* #gNav
================================================ */
#gNav { 
	display: none;
	position: absolute;
	top: 68px;
	right: 0;
	width: 100%;
	z-index: 10000;
	background-color: #fff;
	.list{ 
		margin-bottom: 25px;
		.item{ 
			border-bottom: 1px solid #ccc;
			a{ 
				display: block;
				font-weight: 500;
				color: #257e61;
				text-decoration: none;
				padding: 15px 16px;
			}
		}
	}
	.btnClose{ 
		width: 305px;
		margin: 0 auto 28px;
		background-color: #f4f3f0;
		border-radius: 6px;
		box-shadow: 0px 3px 0px 0px rgba(51, 51, 51, 0.2);
		text-align: center;
		font-size: 1.6rem;
		font-weight: 500;
		color: #257e61;
		padding: 15px 0;
		&:before{ 
			content: '';
			background: url(../img/common/header/ico_close01.png) 0 0 no-repeat;
			background-size: contain;
			width: 17px;
			height: 17px;
			display: inline-block;
			margin: 0px 10px -2px 0;
		}
	}
	@include mq(pc) { 
		display: block;
		position: static;
		width: 1100px;
		margin: auto;
		.list{ 
			margin-bottom: 0;
			.item{ 
				float: left;
				width: calc(1100px / 6);
				border-bottom: none;
				text-align: center;
				border-left: 1px solid #d9d8cf;
				box-sizing: border-box;
				padding: 0 5px;
				&:last-child{ 
					border-right: 1px solid #d9d8cf;
				}
				a{ 
					padding: 4px 0px;
					@include trans;
					&:hover{ 
						background-color: #f6faf5;
					}
				}
			}
		}
	}
}


/* #overlay
================================================ */
#overlay { 
	display: none;
	width: 100%;
	height: 100%;
	background-color: rgba(#000,0.3);
	position: absolute;
	top: 68px;
	left: 0;
	z-index: 999;
	@include mq(pc) { 
		display: none !important;
	}
}
