/*!  #breadcrumb
================================================ */
#breadcrumb { 
	ol { 
		font-size: 0;
		padding: 15px 0;
		li { 
			display: inline-block;
			font-size: 1.4rem;
			color: #4c463d;
			+ li { 
				&:before { 
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 4px 0 4px 5px;
					border-color: transparent transparent transparent #257e61;
					display: inline-block;
					margin: 0 6px 1px 4px;
				}
			}
			a { 
				font-size: 1.4rem;
				text-decoration: none;
				color: #257e61;
				&:hover{ 
					text-decoration: underline;
				}
			}
		}
	}
}