@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  02_base
================================================ */
html { 
	@include mq(pc) { 
		overflow: auto;
	}
}
body { 
	min-width: 320px;
	font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
	color: #4c463d;
	font-size: 1.5rem;
	line-height: 1.6;
	letter-spacing: .08em;
	position: relative;
	@include mq(pc) { 
		min-width: 1100px;
		overflow: hidden;
		border-top: 4px solid #257e61;
	}
}
a { 
	color: #4c463d;
	text-decoration: underline;
	@include mq(pc) { 
		&:hover { 
			text-decoration: none;
		}
	}
}
.alpha { 
	display: block;
	text-decoration: none;
	@include mq(pc) { 
		@include trans;
		&:hover { 
			opacity: .7;
		}
	}
}
.roboto{ 
	font-family: 'Roboto', sans-serif;
}
.serif{ 
	font-family: 'Noto Serif JP', serif;
}

img { 
	width: 100%;
	height: auto;
}
.viewPc {  display: none; }
@include mq(pc) { 
	.viewSp {  display: none; }
	.viewPc {  display: block; }
}
.cf:after { 
	content: '';
	display: block;
	clear: both;
}
#mediaQuery { 
	display: none;
	font-family: 'sp';
	@include mq(pc) { 
		font-family: 'pc';
	}
}
/*! layout
-------------------------------------- */
#wrapper { 
	@include mq(pc) { 
	}
}
#container { 
	@include mq(pc) { 
	}
}

#contents { 
	@include mq(pc) { 
	}
}
